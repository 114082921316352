import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));


export class ErrorPage extends Component {

    async componentDidMount() {
        import('wowjs').then((WOW) => new WOW.WOW().init());
    };
    meta
    render() {

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head title="404 Page" fallback={<Loader />} type="Website" path="/" />
                    <main>
                        <div id="wrapper">
                            <div className="error-page">
                                {/* Banner */}
                                <section className="banner-sec">
                                    <div className="banner-content">
                                        <div className="auto-container">
                                            <div className='row'>
                                                <div className="col-lg-6 col-md-12 col-sm-12 ">
                                                    <div className='banner-text'>
                                                        <h1>
                                                            404
                                                        </h1>
                                                        <p>
                                                            This is not the web page you are looking for.
                                                        </p>
                                                        <Link to={`/`} title="GO back" className="stripped btn-style-one">
                                                            GO back <i className='icon fa fa-angle-right'></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default ErrorPage;